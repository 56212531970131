@tailwind base;
@tailwind components;
@tailwind utilities;
summary {
  cursor: pointer;
  font-size: 20px;
  margin: 15px 0;
}

details {
  font-size: 1.125rem;
}

p {
  font-size: 1.125rem;
}

a {
  color: blue;
  text-decoration: none;
}

body {
  background-image: url('../public/background-final.png');
  background-attachment: fixed;
}

.a2 {
  color: white;
  text-decoration: none;
}

/* apply the grid layout only on screens wider than 768px */
@media screen and (min-width: 1300px) {
  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .item {
    padding: 10px;
    background-color: transparent; /* make the background transparent */
  }

  /* Target odd-numbered items (i.e., the first, third, fifth, etc.) */
  .item:nth-child(odd) {
    margin-right: -100px;
  }

  /* Target even-numbered items (i.e., the second, fourth, sixth, etc.) */
  .item:nth-child(even) {
    margin-left: -100px;
  }
}
